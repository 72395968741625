import React from 'react';
import Layout from '../components/Layout';
import SEO from './../components/SEO';
import ProjectCard from './../components/ProjectCard'

const Projects = ({ data, location }) => {
  const { edges: projects } = data.projects;
  return (
    <Layout>
      <SEO location={location.pathname} title="💡 My projetcs" />
      <div className="bg-white p-5 rounded-md shadow border max-w-5xl mx-auto">
        <h1 className="text-2xl font-bold">Projects</h1>
        <div className="max-w-5xl">
          {projects.map(({ node }) => {
            return (
              <ProjectCard
                key={node.id}
                title={node.frontmatter.title}
                body={node.body}
                date={node.frontmatter.date}
                link={node.frontmatter.link}
                thumbnail={node.frontmatter.thumbnail?.childImageSharp?.fluid}
                emoji={node.frontmatter.emoji}
                status={node.frontmatter.status}
              />
            )
          })
          }
        </div>
        <h1 className="text-2xl font-bold mt-2">Open source</h1>
        <div className="p-1 my-3">
          <div className="flex items-center">
            <a
              href="https://github.com/melbarch/power-my-vscode"
              rel="noopener"
              target="_blank"
            >
              <h3 className="text-lg font-semibold underline">
                power-my-vscode
              </h3>
            </a>
            <img
              className="mx-2 h-4"
              alt="package-version"
              src="https://img.shields.io/npm/v/power-my-vscode"
            />

            <img
              className="h-4"
              alt="package-version"
              src="https://img.shields.io/npm/dt/power-my-vscode"
            />
          </div>
          <p>
            This is a simple CLI utility that installs some predefined
            extensions (theme and extensions) on VS Code.
          </p>
          <p>
            I use it whenever I start working on a new machine to have the same work experience.
          </p>
          <div className="flex items-center">
            <a
              href="https://github.com/melbarch/ghostify-tabs"
              rel="noopener"
              target="_blank"
            >
              <h3 className="text-lg font-semibold underline">ghostify-tabs</h3>
            </a>
            <img
              className="mx-2 h-4"
              alt="package-version"
              src="https://img.shields.io/npm/v/ghostify-tabs"
            />

            <img
              className="h-4"
              alt="package-version"
              src="https://img.shields.io/npm/dt/ghostify-tabs"
            />
          </div>
          <p>
            This was my first npm package! I made this just to learn Node CLIs and a bit of processes manipulation on windows.
          </p>
          <p></p>
          <a
            href="https://github.com/melbarch/next.ts"
            rel="noopener"
            target="_blank"
          >
            <h3 className="text-lg font-semibold underline">next.ts</h3>
          </a>
          <p>
            A dead simple boilerplate with my preferred stack : Next.js boilerplate with Typescript and Tailwindcss.
          </p>
          <p>
            I use it as a starter kit for my new projects
          </p>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    projects: allMdx(filter: {frontmatter: {type: {eq: "project"}}},
    sort: {order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          id
          excerpt
          body
          frontmatter {
            title
            date(formatString: "MMMM YYYY")
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 630) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            emoji
            status
            link
          }
          slug
        }
      }
    }
  }
`;

export default Projects;
